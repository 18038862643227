<template>
  <div class="login">
    <div class="login-content">
      <div class="bg-content"></div>
      <div class="right-content">
        <div class="right-content_box">
          <!-- <div class="right-content_box"> -->
          <h1>快货运支付后台</h1>
          <!-- <span class="right-content_title">手机验证码登录</span> -->
          <!-- </div> -->

          <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
            <el-tab-pane label="密码登录" name="first">
              <el-form
                :model="ruleFormPassword"
                :rules="rulesPassword"
                ref="ruleFormPassword"
                class="ruleForm"
              >
                <el-form-item prop="mobile">
                  <el-input
                    class="inline-input"
                    v-model="ruleFormPassword.mobile"
                    placeholder="请输入手机号码"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-phone"></i
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    class="inline-input"
                    v-model="ruleFormPassword.password"
                    show-password
                    @keyup.enter.native="submitForm('ruleFormPassword')"
                    placeholder="请输入密码"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-lock"></i
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    class="login_style"
                    type="primary"
                    @click="submitForm('ruleFormPassword')"
                    :loading="fullscreenLoading"
                    >登录</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="短信登录" name="second">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                class="ruleForm"
              >
                <el-form-item prop="mobile">
                  <el-autocomplete
                    class="inline-input"
                    v-model="ruleForm.mobile"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入手机号码"
                    @select="handleSelect"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-phone"></i
                    ></template>
                  </el-autocomplete>
                </el-form-item>
                <el-form-item prop="code">
                  <el-input
                    v-model="ruleForm.code"
                    placeholder="请输入验证码"
                    autocomplete="off"
                    @keyup.enter.native="submitForm('ruleForm')"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-s-comment"></i
                    ></template>
                    <el-button
                      slot="append"
                      type="text"
                      @click="getCode"
                      class="codeStyle"
                      :class="{ isColor: !disabled }"
                      :disabled="disabled"
                      >{{ valTxt }}</el-button
                    >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    class="login_style"
                    type="primary"
                    @click="submitForm('ruleForm')"
                    :loading="fullscreenLoading"
                    >登录</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginCode, sendCode, getApi, loginPwd } from '@/request/api'
import cookies from 'vue-cookies'
import { some } from 'lodash'
import md5 from 'js-md5'

export default {
  name: 'login',
  data() {
    return {
      phoneList: [],
      activeName: 'first',
      valTxt: '获取验证码',
      disabled: false,
      password: '1',
      ruleForm: {
        mobile: '',
        code: '',
      },
      ruleFormPassword: {
        mobile: '',
        password: '',
      },
      rulesPassword: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            min: 11,
            max: 11,
            message: '请输入11位手机号码',
            trigger: 'blur',
          },
          {
            pattern: /^(1+\d{10})$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            min: 11,
            max: 11,
            message: '请输入11位手机号码',
            trigger: 'blur',
          },
          {
            pattern: /^(1+\d{10})$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      fullscreenLoading: false,
    }
  },
  mounted() {
    let phoneString = localStorage.getItem('phone_list')
    if (phoneString) {
      this.phoneList = JSON.parse(phoneString)
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    querySearch(queryString, cb) {
      var restaurants = this.phoneList
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    handleSelect(item) {
      // console.log(this.ruleForm.mobile)
      console.log(item)
    },
    getCode() {
      this.$refs.ruleForm.validateField('mobile', (v) => {
        if (!v) {
          sendCode({ mobile: this.ruleForm.mobile, type: 'MANAGE_LOGIN_SMS' })
            .then((res) => {
              if (res.status == 200) {
                if (!res.data.success) {
                  this.$message({
                    message: res.data.failMsg,
                    type: 'error',
                    center: 'true',
                  })
                } else {
                  this.$message({
                    message: '验证码发送成功！',
                    type: 'success',
                    center: 'true',
                  })
                  this.tackBtn()
                }
              } else {
                this.$message({
                  message: res.message,
                  type: 'error',
                  center: 'true',
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log(v)
        }
      })
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60
      this.disabled = true
      this.valTxt = time + '秒后重试'
      time--
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer)
          this.valTxt = '获取验证码'
          this.disabled = false
        } else {
          this.disabled = true
          this.valTxt = time + '秒后重试'
          time--
        }
      }, 1000)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true
          this.submitFormDo(formName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async submitFormDo(formName) {
      try {
        const res =
          formName === 'ruleForm'
            ? await loginCode(this.ruleForm)
            : await loginPwd({
                mobile: this.ruleFormPassword.mobile,
                loginPwd: md5(this.ruleFormPassword.password),
              })
        this.fullscreenLoading = false
        if (res.status == 200) {
          this.getPerApi()
          // this.$message({
          //   message: '登录成功',
          //   type: 'success',
          //   center: 'true',
          // })
          const mobile =
            formName === 'ruleForm'
              ? this.ruleForm.mobile
              : this.ruleFormPassword.mobile
          cookies.set('mobile', mobile)
          let storePhoneArry = localStorage.getItem('phone_list')
            ? JSON.parse(localStorage.getItem('phone_list'))
            : []
          let isHad = some(storePhoneArry, { value: mobile })
          if (!isHad) {
            storePhoneArry.unshift({ value: mobile })
            if (storePhoneArry.length > 10) {
              storePhoneArry.pop()
            }
            let phoneString = JSON.stringify(storePhoneArry)
            localStorage.setItem('phone_list', phoneString)
          }

          // setTimeout(() => {
            this.$router.push({ path: '/index' })
          // }, 500)
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            center: 'true',
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取角色权限
    // getUserPermissionsList() {
    //   getUserPerList({}).then(res => {
    //     if (res.status == 200) {
    //       localStorage.setItem("userPermissionsList", JSON.stringify(res.data));
    //     }
    //   });
    // },
    // 获取全部权限api
    getPerApi() {
      getApi({}).then((res) => {
        if (res.status == 200) {
          localStorage.setItem('permissionsList', JSON.stringify(res.data))
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.isColor {
    color: $spColor !important;
}
.inline-input{width:313px;}
.login {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background-color: #003580;
    font-size: 14px;
}

.login-content {
    // width: 100%;
    // height: 100%;
    // position: relative;
}

.bg-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: url('//oss.kuaihuoyun.com/khy/img/zfhtBg.png') no-repeat center center transparent;
    background-size: cover;
}

.right-content {
    width: 400px;
    height: 100%;
    background: #fff;
    position: absolute;
    right: 0;
    padding: 0 40px;
    box-sizing: border-box;
}

.right-content_box {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    overflow: hidden;
}

.ruleForm {
    clear: both;
    overflow: hidden;
    padding-top:20px;
}

h1 {
    color: #333;
    font-size: 34px;
    margin-bottom: 150px;
    font-weight: bold;
}

.right-content_title {
    font-size: 14px;
    line-height: 26px;
    display: inline-block;
    color: #007AFF;
    position: relative;
    float: right;
    margin-bottom: 30px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        margin-left: -9px;
        width: 18px;
        height: 2px;
        background: #007AFF;
    }
}

.el-form-item__content {
    margin-left: 0 !important;
}

.login >>> .el-input-group__append {
    border: 0 !important;
    background: #fff !important;
}

.login >>> .el-button--text {
    padding: 0 5px;
    width: 80px;
}

.login_style {
    width: 100%;
    margin-top: 20px;
}
</style>
